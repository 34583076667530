import React, { Component } from 'react';
import Header from "../component/header/HeaderFour";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/FooterTwo";

 class Impressum extends Component {

    render() {
        return (
            <>
                <Header headerPosition="header--transparent" color="color-white" logo="logo-light" />
                {/* Start Page Error  */}
                <div className="content-page-inner bg_color--4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                              <div className="inner">
                                <h1>Impressum</h1>

                                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                                <p>Bastian Platz<br />
                                  Opportunity Scout<br />
                                  Gardes-du-Corps-Str. 15<br />
                                  Geb&auml;ude 44<br />
                                  14059 Berlin</p>

                                <h2>Kontakt</h2>
                                <p>Telefon: +491786801476<br />
                                  E-Mail: mail@bastianplatz.de</p>

                                <h2>Umsatzsteuer-ID</h2>
                                <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                                  DE274817022</p>

                                <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                                <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Error  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </>
        )
    }
}
export default Impressum;
