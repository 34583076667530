import React from 'react';
import ScrollToTop from 'react-scroll-up';
import {FiCheck, FiChevronUp} from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";

const SlideList = [
  {
    textPosition: 'text-left',
    category: 'Welcome to my World',
    description: '',
    buttonText: '',
    buttonLink: ''
  }
];
const BastianPlatz = () => {
  let title = 'About Me',
    description = 'Developer by education and experience.';
  const PostList = BlogContent.slice(0, 3);
  return (
    <div className="active-dark bg_color--9">
      <Helmet pageTitle="BastianPlatz.de"/>

      <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/>

      {/* Start Slider Area   */}
      <div id="home" className="fix">
        <div className="slider-wrapper">
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25"
              key={index}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ''}
                      <h1 className="title">I’m Bastian Platz <br/>
                        <TextLoop>
                          <span> Opportunity Scout.</span>
                          <span> Invention of products.</span>
                          <span> Invention of services.</span>
                        </TextLoop>{" "}
                      </h1>
                      <h2>Made in Germany <br/>for the world.</h2>
                      {value.description ? <p className="description">{value.description}</p> : ''}
                      {value.buttonText ? <div className="slide-btn mt--30"><a className="btn-default btn-border btn-opacity"
                                                                               href={`${value.buttonLink}`}>{value.buttonText}</a>
                      </div> : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
      </div>
      {/* End Slider Area   */}

      {/* Start Philosophy Area */}
      <div id="philosophy" className="fix">
        <div className="about-area ptb--120  bg_color--8">
          <div className="about-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="portfolio-details">
                    <div className="row row--35 mb--50">
                      <div className="col-lg-6">
                        <div className="inner">
                          <div className="section-title">
                            <h3 className="title">Opportunity Scout</h3>
                            <p className="description mt--30">
                              <strong>Opportunity Scout</strong> » inventing feasible, viable, and desirable products and services.
                            </p>
                            <p className="description mt--30">
                              As a creative athlete, I don't just run the metaphorical mile in under four minutes—I craft a unique
                              masterpiece each time, complete with measurable economic value. Imagine a great artist sketching out a
                              blueprint that simply needs to be executed and marketed. That's the essence of what I do.
                            </p>
                            <p className="description mt--30">
                              For me, it's not about quantity but quality. I deliver not just simple ideas, but genuine "aha" moments and
                              groundbreaking concepts. This is about taking the podium spots, be it bronze, silver, or especially gold, in
                              the world of creativity.
                            </p>
                            <p className="description mt--30">
                              My creative process is a symbiosis of tried-and-true methods and pioneering spirit. Inspired by thought
                              leaders like Fritz Zwicky and Edward de Bono, I continually refine my strategies and elevate them to a new
                              level. All this is executed with the precision of a German engineer, and standardization according to DIN SPEC
                              is already in the pipeline.
                            </p>
                            <p className="description mt--30">
                              While many are content to play in the realm of mass ideation, I strive for the creative Olympics. And there,
                              only the best have a place.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                          <div className="inner">
                            <div className="section-title">
                              <h3 className="title">Philosophy</h3>
                              <p className="description mt--30">
                                The attributes of excellent innovation:
                              </p>
                              <ul className="list-style--1">
                                <li><FiCheck/> feasibility</li>
                                <li><FiCheck/> viability</li>
                                <li><FiCheck/> desirability</li>
                              </ul>
                              <p className="description mt--30">
                                Disrupt or be disrupted.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row row--35 mb--50">
                      <div className="col-lg-6">
                        <div className="inner">
                          <div className="section-title">
                            <h3 className="title">Quotes</h3>
                            <p className="description">
                              "The best way to have a good idea is to have a lot of ideas."
                              <br/>
                              <strong>→ Linus Pauling</strong>
                            </p>
                            <p className="description">
                              "How many insights do you need! Well, I'd argue that you don't need many in a lifetime. If you look at
                              Berkshire Hathaway and all of its accumulated billions, the top ten insights account for most of it."
                              <br/>
                              <strong>→ Charlie Munger</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="inner">
                          <div className="section-title">
                            <p className="description" style={{marginTop: 41}}>
                              "Everyone is a genius at least once a year. The real geniuses simply have their bright ideas closer
                              together."
                              <br/>
                              <strong>→ Georg C. Lichtenberg</strong>
                            </p>
                            <p className="description">
                              "Discovering means seeing what everyone sees and thinking what no one has thought before."
                              <br/>
                              <strong>→ Albert Szent-Györgyi, nobel price in medicine, 1937</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Philosophy Area */}

      {/* Start Services Area  */}
      <div id="services" className="fix">
        <div className="service-area creative-service-wrapper ptb--120 bg_color--8">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30 mb_sm--0">
                  <span className="subtitle">What I can do for you</span>
                  <h2 className="title">Opportunity Scout</h2>
                  <p className="description">I invent new services and products. You add them to your business. We succeed.</p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Services Area  */}

      {/* Start About Area */}
      <div id="about" className="fix">
        <div className="about-area ptb--120  bg_color--8">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img className="w-100" src="/assets/images/about/256x256.jpg" alt="About Images"/>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <span className="subtitle">My About Details</span>
                      <h2 className="title mb--20">{title}</h2>
                      {/*<p className="description mt_dec--20">{description}</p>*/}
                    </div>
                    <div className="row mt--40">
                      <TabTwo tabStyle="tab-style--1"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}

      {/* Start Contact Area */}
      {/*
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--8">
                    <ContactThree contactImages="/assets/images/about/kenny-eliason-1.jpg" />
                </div>
            </div>
            */}
      {/* End Contact Area */}

      <FooterTwo/>

      {/* Start Back To Top */}
      <div className="backto-top" onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'instant' })}>
        <div style={{
          position: "fixed",
          bottom: 50,
          right: 30,
          cursor: "pointer",
          transition: "opacity 0.2s linear, visibility",
          opacity: 1,
          visibility: "visible"
        }}>
          <FiChevronUp/>
        </div>
      </div>
      {/* End Back To Top */}

    </div>
  );
};

export default BastianPlatz;
