// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

import PageScrollTop from './component/PageScrollTop';
import error404 from "./elements/error404";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
//import * as serviceWorker from './serviceWorker';
import BastianPlatz from "./dark/BastianPlatz";
import Datenschutzerklaerung from "./elements/Datenschutzerklaerung";
import Impressum from "./elements/Impressum";
import ServiceDetails from "./elements/ServiceDetails";

class Root extends Component{
  render(){
    return(
      <BrowserRouter basename={'/'}>
        <PageScrollTop>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={BastianPlatz}/>
            <Route exact path={`${process.env.PUBLIC_URL}/opportunity-briefs`} component={ServiceDetails}/>

            <Route path={`${process.env.PUBLIC_URL}/datenschutzerklaerung`} component={Datenschutzerklaerung}/>
            <Route path={`${process.env.PUBLIC_URL}/impressum`} component={Impressum}/>

            <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
            <Route component={error404}/>

          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    )
  }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
//serviceWorker.register();
