import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn } from "react-icons/fa";
import { FiX , FiMenu} from "react-icons/fi";

const SocialShare = [
    /*{Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},*/
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/bastianplatz'},
    /*{Social: <FaInstagram /> , link: 'https://www.instagram.com/'},*/
    /*{Social: <FaTwitter /> , link: 'https://twitter.com/BastianPlatz'},*/
]
class HeaderThree extends Component{
    state = {
        current: 'home'
    }

    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader () {}
    render(){
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });
        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { logo, color='default-color' } = this.props;
        const logoUrl = <img src="/assets/images/about/256x256.jpg" alt="Bastian Platz" />;


        const scrollTo = (id) => (e) => {
            e.preventDefault()
            e.stopPropagation()
            this.setState({current: id})
            setTimeout(() => {
                const el = document.getElementById(id)
                const rect = el.getBoundingClientRect();
                window.scrollBy({ top: rect.y, left: rect.x, behavior: 'instant'  });
            }, 10);
        }

        return(
            <header className={`header-area header-style-two header--fixed ${color}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                            <a href={this.props.homeLink}>
                                {logoUrl}
                            </a>
                        </div>
                        <nav className="mainmenunav d-lg-block ml--50">
                            <ul className="mainmenu">
                                {['Home', 'Philosophy', 'Services', 'About'/*, 'Contact'*/].map((val) => (
                                  <li key={val} className={(this.state.current === val.toLowerCase()) ? 'is-current' : ''}>
                                      <a href="#" onClick={scrollTo(val.toLowerCase())}>{val}</a>
                                  </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                    <div className="header-right">
                        <div className="social-share-inner d-none d-sm-block">
                            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`} target="_blank" rel="noopener noreferrer">{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderThree;
