import React ,{ Component }from "react";
import {FiCast, FiLayers, FiUsers, FiMonitor, FiBook} from "react-icons/fi";
import {AiFillBulb} from "react-icons/all";
import Link from "react-router-dom/es/Link";

const ServiceList = [
    {
        icon: <FiLayers />,
        title: 'Opportunity Briefs',
        description: 'Let me invent a product or service for you. I will come up with a product or service idea that is feasible, viable, and desirable.',
        url: '/opportunity-briefs'
    },
    /*{
        icon: <FiLayers />,
        title: 'Service Ideation',
        description: 'Let me ideate a service for you. I will come up with a service idea that is feasible, viable, and desirable.',
        url: ''
    },*/
    {
        icon: <FiBook />,
        title: 'Book',
        description: 'The Business of Combinations - Beat compounding interest. Save time. Make it happen in years, not decades...',
        url: 'https://bit.ly/3xbf9Ej',
        target: '_blank',
        link: 'pdf for free',
        toUrl: 'https://bit.ly/3xbf9Ej'
    },
    /*
    {
        icon: <FiMonitor />,
        title: 'Mobile App Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing & Reporting',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiMonitor />,
        title: 'Mobile App Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    }
   */
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);

        return(
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>

                                  <a href={val.url} onClick={val.url?undefined:(e)=>{e.preventDefault(); e.stopPropagation()}}>
                                      <div className="service service__style--2 text-left bg-gray">
                                          <div className="icon">
                                              {val.icon}
                                          </div>
                                          <div className="content">
                                              <h3 className="title">{val.title}</h3>
                                              <p>{val.description}</p>
                                              {
                                                  val.link ? <a href={val.toUrl} target={val.target}>{val.link}</a> : null
                                              }
                                          </div>
                                      </div>
                                  </a>
                                </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
