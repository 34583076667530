import React from 'react';
import {FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn} from "react-icons/fa";
import Impressum from "../../elements/Impressum";

const SocialShare = [
  /*{Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},*/
  { Social: <FaLinkedinIn/>, link: 'https://www.linkedin.com/in/bastianplatz' },
  /*{Social: <FaInstagram /> , link: 'https://www.instagram.com/'},*/
  /*{Social: <FaTwitter /> , link: 'https://twitter.com/BastianPlatz/'},*/
];

const FooterTwo = () => {
  return (
    <div className="footer-style-2 ptb--30 bg_color--6">
      {/* Start Call to Action Area  */}
      <div className="im-call-to-action-area ptb--70 im-separator">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-xl-6 col-md-12 col-sm-12 col-12">
              <div className="inner">
                <h2 className="text-white mb--0">Enough Talk, Let's Invent Something Together</h2>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4 offset-xl-2 col-md-12 col-sm-12 col-12">
              <div className="call-to-cation-tbn text-left text-lg-right mt_md--20 mt_sm--20">
                <a className="btn-default btn-large btn-border btn-opacity" href="https://calendly.com/bastianplatz/30min" target="_blank" rel="noopener noreferrer">Get a Goal of Innovations Call</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper plr--50 plr_sm--20 mr--40">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner">
              <div className="logo text-center text-sm-left mb_sm--20">
                <a href="/">
                  <img src="/assets/images/about/256x256.jpg" alt="Logo images"/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner text-center">
              <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}><a href={`${val.link}`} target="_blank" rel="noopener noreferrer">{val.Social}</a></li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
              <div className="text">
                <p>
                  Copyright © 2022-2023 Bastian Platz. All Rights Reserved.
                  <br/>
                  <a href="/impressum">Impressum</a>
                  <br/>
                  <a href="/datenschutzerklaerung">Datenschutzerklärung</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterTwo;
